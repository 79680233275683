import { Button, Heading, Link } from '@troon/ui';
import { Content } from '../../../../../components/main-content';
import type { RouteSectionProps } from '@solidjs/router';

export default function FacilitySettings(props: RouteSectionProps) {
	return (
		<Content>
			<Heading as="h1">Facility Info</Heading>

			<div class="size-fit">
				<Button as={Link} href={`/facility/${props.params.facility}/settings/members`}>
					Manage Operators
				</Button>
			</div>
		</Content>
	);
}
